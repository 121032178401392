.item {
}
.item__li {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.item__li + .item__li {
  margin-top: 48px;
}
.item__li__pic {
  width: 600px;
  height: 400px;
  flex-shrink: 0;
  display: block;
  position: relative;
}
.item__li__pic:hover {
  opacity: 0.8;
}
.item__li:nth-child(odd) .item__li__pic {
  order: 0;
  margin-right: 32px;
}
.item__li:nth-child(odd) .item__li__info {
  order: 1;
}
.item__li:nth-child(even) .item__li__pic {
  order: 1;
  margin-left: 32px;
}
.item__li:nth-child(even) .item__li__info {
  order: 0;
}
.item__li__pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.item__li__info {
}
.item__li__info__ttl {
  font-size: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-all;
}
.item__li__info__tag {
  margin-top: 16px;
  margin-bottom: -4px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.item__li__info__tag p {
  font-size: 12px;
  line-height: 22px;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  padding: 0 4px;
  margin-right: 4px;
  margin-bottom: 4px;
  display: inline-block;
  word-break: break-all;
}
.item__li__info__desc {
  margin-top: 16px;
  font-size: 14px;
  color: #888;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
}
.item__li__info__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
}
.item__li__info__bottom__left {
}
.item__li__info__bottom__left__week {
  font-size: 14px;
}
.item__li__info__bottom__left__erea {
  font-size: 16px;
  font-weight: bold;
}
.item__li__info__bottom__price {
  font-size: 24px;
  font-weight: bold;
  text-align: right;
}
.item__li__info__btn {
  width: 240px;
  height: 40px;
  font-family: 'Raleway', 'ヒラギノ角ゴ Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo',
    sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.1em;
  border-radius: 2px;
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.item__li__info__btn::after {
  content: '';
  width: 8px;
  height: 8px;
  border-top-width: 1px;
  border-right-width: 1px;
  border-top-style: solid;
  border-right-style: solid;
  transform: rotate(45deg);
  position: absolute;
  top: calc(50% - 4px);
  right: 16px;
}
.item__li__info__btn:hover {
  opacity: 0.8;
}
@media screen and (max-width: 768px) {
  .item__li {
    display: block;
  }
  .item__li__pic {
    width: 100%;
    height: 240px;
    flex-shrink: 0;
  }
  .item__li__pic:hover {
    opacity: 0.8;
  }
  .item__li:nth-child(odd) .item__li__pic {
    margin-right: 0;
  }
  .item__li:nth-child(even) .item__li__pic {
    margin-left: 0;
  }
  .item__li__info {
    display: flex;
    flex-direction: column;
  }
  .item__li__info > * {
    order: 2;
  }
  .item__li__info__ttl {
    font-size: 18px;
    -webkit-line-clamp: 2;
    margin-top: 8px;
    order: 0;
  }
  .item__li__info__tag {
    margin-top: 8px;
    order: 1;
  }
  .item__li__info__desc {
    margin-top: 8px;
  }
  .item__li__info__bottom {
    margin-top: 16px;
  }
  .item__li__info__btn {
    margin-left: auto;
    margin-right: auto;
  }
  .item__li__info__bottom__left__week {
    font-size: 12px;
  }
  .item__li__info__bottom__left__erea {
    font-size: 14px;
  }
  .item__li__info__bottom__price {
    font-size: 18px;
  }
}
@media screen and (max-width: 768px) {
}

/* VERTICAL orientation */
.c-card {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  transition: box-shadow 0.4s ease;
}
.c-card::before {
  content: '';
  width: calc(100% + 16px);
  height: calc(100% + 16px);
  position: absolute;
  top: -8px;
  left: -8px;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
}
.c-card:hover::before {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
}
.c-card__pic {
  width: 100%;
  height: 200px;
  background: #ddd;
  overflow: hidden;
  position: relative;
}
.c-card__pic img {
  width: inherit;
  height: inherit;
  object-fit: cover;
}
.c-card__header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 8px;
}
.c-card__header__star {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: auto;
}
.c-card__header__star img {
  width: 16px;
  height: 16px;
  margin-right: 2px;
}
.c-card__header__star span:nth-of-type(1) {
  margin-right: 2px;
}
.c-card__header__star span:nth-of-type(2) {
  color: #888;
}
.c-card__header__number {
  color: #ff0045;
  margin-right: 8px;
}
.c-card__header__tags {
  position: relative;
  height: 22px;
}
.c-card__header__tags__txt {
  width: 64px;
  height: 22px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.c-card__header__tags__txt > div {
  width: 14px;
  height: 14px;
  margin-right: 2px;
}
.c-card__header__tags__txt img,
.c-card__header__tags__txt svg {
  width: inherit;
  height: inherit;
}
.c-card__header__tags__list {
  display: none;
}
.c-card__header__tags:hover .c-card__header__tags__list,
.c-card__header__tags:active .c-card__header__tags__list {
  max-width: 600px;
  max-height: 200px;
  padding: 4px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 1px 16px rgba(0, 0, 0, 0.1);
  display: block;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  bottom: 22px;
  right: -16px;
}
.c-card__header__tags__list li {
}
.c-card__header__tags__list li p,
.c-card__header__tags__list li a {
  width: 100%;
  line-height: 32px;
  font-size: 13px;
  padding-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
}
.c-card__ttl {
  width: 100%;
  height: 40px;
  font-size: 15px;
  line-height: 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-top: 8px;
}
.c-card__description {
  width: 100%;
  font-size: 12px;
  color: #888;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 8px;
}
.c-card__bottom {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 8px;
}
.c-card__bottom__week {
  font-size: 12px;
  color: #888;
  margin-right: auto;
}
.c-card__bottom__price {
  font-size: 16px;
  font-weight: bold;
}

.c-card__pic__labelframe {
  position: absolute;
  top: 8px;
  left: 0;
}
.c-card__pic__labelframe > div + div {
  margin-top: 4px;
}
.c-card__pic__labelframe__recommend {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.c-card__pic__labelframe__recommend div {
  width: 32px;
  height: 32px;
  border: 1px solid #fff;
  border-radius: 100%;
  margin-left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}
.c-card__pic__labelframe__recommend div {
  background: #2cb582;
}
.c-card__pic__labelframe__recommend div img {
  width: 16px;
  height: 16px;
}
.c-card__pic__labelframe__recommend p {
  width: 132px;
  height: 24px;
  font-size: 12px;
  font-weight: bold;
  color: #ff0045;
  background: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 40px;
  margin-left: -36px;
  position: relative;
}
.c-card__pic__labelframe__recommend p::before,
.c-card__pic__labelframe__recommend p::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 24px 8px 0 0;
  border-color: #fff transparent transparent transparent;
  position: absolute;
  top: 0;
  right: -8px;
}
.c-card__pic__labelframe__recommend p::after {
  transform: scale(1, -1);
}
.c-card__bottom__original__price {
  text-align: right;
  color: #888;
  text-decoration: line-through;
}
.c-card__pic__labelframe__promotion {
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background: #ff0045;
  padding: 0 8px;
  position: relative;
  display: inline-block;
}
.c-card__pic__labelframe__promotion::before,
.c-card__pic__labelframe__promotion::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 24px 8px 0 0;
  border-color: #ff0045 transparent transparent transparent;
  position: absolute;
  top: 0;
  right: -8px;
}
.c-card__pic__labelframe__promotion::after {
  transform: scale(1, -1);
}
